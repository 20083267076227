html, css {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Add this CSS to your styles.css or inside a <style> tag */

.menu-items {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the items */
}

.menu-items.open {
    max-height: 500px; /* Set a maximum height that can accommodate your menu items */
}

.menu-item {
    width: 100%; /* Ensure the menu item takes the full width of the container */
    display: flex;
    justify-content: center; /* Center the content inside the menu item */
    padding: 5px 0;
}

.menu-item a {
    display: flex;
    align-items: center; /* Align items in the center vertically */
    text-decoration: none;
    color: black;
}

.page-break {
    page-break-before: always;
}

.page-break-after{
    page-break-after: always;
}

.avoid-page-break {
    page-break-inside: avoid;
}


.menu-item a .icon {
    margin-right: 5px; /* Adjust the spacing between the icon and the text */
}


::-webkit-scrollbar-thumb {
    background-color: #65C7EE;
    border-radius: 99px;
}

@font-face {
    font-family: 'Axia';
    src: url('../assets/fonts/Axia-Regular.otf');
}

.gradient-summary-divider {
    width: 2px;
    background: linear-gradient(to bottom, blue, red);
}

.gradient-hover-effect {
    display: flex;
    padding: 0.875em 2em;
    font-family: inherit;
    color: #fff;
    text-decoration: none;
    place-content: center;
    border: solid 1px transparent;
    border-radius: 10px;
    transition: 0.25s !important;
    background: black;
}

.gradient-hover-effect:hover, .gradient-hover-effect:focus {
    background: radial-gradient(circle at 50% 100%, rgb(14, 54, 179, 0.6) 0%, rgb(0, 0, 0) 50%);
    color: #fff;
    border: solid 1px rgb(25, 54, 139);
}

.create-agent-card {
    display: flex;
    padding: 0.875em 2em;
    font-family: inherit;
    color: #fff;
    text-decoration: none;
    place-content: center;
    border: solid 1px transparent;
    border-radius: 30px;
    transition: 0.5s !important;
    color: #fff;
    border: solid 1px rgb(18, 38, 99);
    background: radial-gradient(circle at 20% 100%, rgb(2, 19, 70) 0%, rgb(0, 0, 0) 100%) !important;
}
